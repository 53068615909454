<template>
  <runai-base-dialog :model-value="true" class="tool-access-modal" size="width-md" @close="$emit('close')">
    <template #header>
      <div class="tool-access-modal-header">Access to {{ toolName }}</div>
    </template>

    <template #body>
      <main class="tool-access-modal-body">
        <div class="q-my-sm">Set who is authorized to access the tool</div>
        <q-form :ref="formRef">
          <div class="options-container q-mb-md">
            <q-radio
              v-for="opt in accessOptions"
              v-model="selectedOptions"
              @update:model-value="onSelectedOptionChanged"
              :val="opt.value"
              :key="opt.value"
              class="access-option"
              :aid="opt.aid"
            >
              <span class="q-mr-md">{{ opt.label }}</span>
              <runai-tooltip
                v-if="opt.description"
                :tooltip-text="opt.description"
                width="350px"
                tooltip-position="right"
              />
            </q-radio>
          </div>

          <div class="users-list" v-if="selectedOptions === 'specific-users'">
            <div class="q-mb-sm">
              <span>Add the users which can access the tool</span>
              <runai-tooltip
                tooltip-text="Insert valid email addresses. If you remove yourself, you won't have access to the tool."
                width="300px"
                tooltip-position="right"
              />
            </div>
            <access-input-list
              input-label="User email"
              :items="users"
              add-item-btn-label="User"
              :validation-rules="[isValidEmail, isEmailExist]"
              @changed="onUsersChanged"
            ></access-input-list>
          </div>

          <div class="groups-list" v-if="selectedOptions === 'groups'">
            <div class="q-mb-sm">
              <span>Add the groups which can access the tool</span>
              <runai-tooltip
                tooltip-text="Enter group names as they appear in your identity provider. If you are not a member of one of the groups listed, you won't have access to the tool."
                width="300px"
                tooltip-position="right"
              />
            </div>
            <access-input-list
              input-label="Group name"
              add-item-btn-label="Group"
              :items="groups"
              :validation-rules="[groupNotEmpty, isNameExist]"
              @changed="onGroupsChanged"
            ></access-input-list>
          </div>
        </q-form>
      </main>
    </template>

    <template #footer>
      <q-btn label="Cancel" color="primary" flat @click="$emit('close')" aid="cancel-tool-access-role" />
      <q-btn label="Save" color="primary" @click="onSave" aid="save-tool-access-role" />
    </template>
  </runai-base-dialog>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

// cmps
import { RunaiBaseDialog } from "@/components/common/runai-base-dialog";
import { RunaiTooltip } from "@/components/common/runai-tooltip";
import { AccessInputList } from "@/components/environment/form-tools-section/tool-box/tool-access/access-input-list";

// models
import { type IToolAccess, EToolLabel } from "./tool-access-modal.model";
import type { IAccessInput } from "@/components/environment/form-tools-section/tool-box/tool-access/access-input-list";
import { EAccessOptions, IAccessOption } from "./tool-access-modal.model";

// utils
import { convertNamesToItems } from "./tool-access-modal.util";

import { errorMessages } from "@/common/error-message.constant";
import { isValidEmail } from "@/common/form.validators";

export default defineComponent({
  name: "tool-access-modal",
  components: {
    RunaiBaseDialog,
    RunaiTooltip,
    AccessInputList,
  },
  emits: ["save", "close"],
  props: {
    accountName: {
      type: String as PropType<string>,
      required: true,
    },
    toolName: {
      type: String as PropType<string>,
      required: true,
    },
    access: {
      type: Object as PropType<IToolAccess>,
      required: true,
    },
    defaultUser: {
      type: String as PropType<string>,
      required: true,
    },
    multiUsers: {
      type: Boolean as PropType<boolean>,
      required: false,
    },
  },
  data() {
    return {
      selectedOptions: EAccessOptions.EVERYONE as string,
      accessOptions: [
        {
          label: `Everyone at ${this.accountName}`,
          value: EAccessOptions.EVERYONE,
          aid: EAccessOptions.EVERYONE,
        },
      ] as IAccessOption[],
      users: undefined as IAccessInput[] | undefined,
      groups: undefined as IAccessInput[] | undefined,
      formRef: "tool-access-modal-form-el" as string,
    };
  },
  created() {
    if (this.multiUsers) {
      this.accessOptions.push({
        label: "Group(s)",
        value: EAccessOptions.GROUPS,
        description: "Groups from your identity provider",
        aid: EAccessOptions.GROUPS,
      });
      this.accessOptions.push({
        label: "User(s)",
        value: EAccessOptions.SPECIFIC_USERS,
        aid: EAccessOptions.SPECIFIC_USERS,
      });
    } else
      this.accessOptions.push({
        label: "Private",
        value: EAccessOptions.PRIVATE,
        description: "When selected, only the workload creator will have access to the tool",
        aid: EAccessOptions.PRIVATE,
      });

    if (this.access.authorizedGroups) {
      this.onGroupsSelected();
    } else if (this.access.authorizedUsers) {
      this.multiUsers ? this.onUsersSelected() : this.onPrivateSelected();
    } else {
      this.selectedOptions = EAccessOptions.EVERYONE;
    }
  },
  methods: {
    onSelectedOptionChanged(): void {
      switch (this.selectedOptions) {
        case EAccessOptions.GROUPS:
          this.onGroupsSelected();
          break;
        case EAccessOptions.SPECIFIC_USERS:
          this.onUsersSelected();
          break;
        case EAccessOptions.PRIVATE:
          this.onPrivateSelected();
          break;
        default:
          this.groups = undefined;
          this.users = undefined;
          break;
      }
    },
    onGroupsSelected(): void {
      this.selectedOptions = EAccessOptions.GROUPS;
      const authorizedGroups = this.access.authorizedGroups || [];
      this.groups = convertNamesToItems(authorizedGroups, EToolLabel.GroupName);
      this.users = undefined;
    },
    onUsersSelected(): void {
      this.selectedOptions = EAccessOptions.SPECIFIC_USERS;
      const authorizedUsers = this.access.authorizedUsers || [this.defaultUser];
      this.users = convertNamesToItems(authorizedUsers, EToolLabel.UserEmail);
      this.groups = undefined;
    },
    onPrivateSelected(): void {
      this.selectedOptions = EAccessOptions.PRIVATE;
      this.groups = undefined;
      this.users = [this.defaultUser].map((user) => ({ text: user, label: "", removable: false }));
    },
    onGroupsChanged(groups: IAccessInput[]): void {
      this.groups = groups;
      this.users = undefined;
    },
    onUsersChanged(users: IAccessInput[]): void {
      this.users = users;
      this.groups = undefined;
    },
    async isSelectionIsValid(): Promise<boolean> {
      const formEl = this.$refs[this.formRef] as HTMLFormElement;
      return await formEl?.validate();
    },
    async onSave(): Promise<void> {
      const isAllowedToSave = await this.isSelectionIsValid();
      if (!isAllowedToSave) return;

      this.$emit("save", {
        authorizedUsers: this.users?.map((user) => user.text),
        authorizedGroups: this.groups?.map((group) => group.text),
      });
    },
    isValidEmail(val: string): boolean | string {
      return isValidEmail(val) || errorMessages.EMAIL_NOT_VALID;
    },
    isEmailExist(val: string): boolean | string {
      if (!this.users?.length) return true;
      const isExist = this.users.filter((user) => user.text === val);
      return isExist.length <= 1 || errorMessages.EMAIL_ALREADY_EXIST;
    },
    groupNotEmpty(val: string): boolean | string {
      return val?.length ? true : errorMessages.NAME_NOT_EMPTY;
    },
    isNameExist(val: string): boolean | string {
      if (!this.groups?.length) return true;
      const isExist = this.groups.filter((group) => group.text === val);
      return isExist.length <= 1 || errorMessages.NAME_ALREADY_EXIST;
    },
  },
});
</script>

<style lang="scss">
.tool-access-modal {
  .access-option {
    width: 100%;
  }
}
</style>
