import type { IScopeModel, IUIAssetCreationMeta } from "@/models/global.model";
import { Scope } from "@/swagger-models/assets-service-client";

export const scopeUtil = {
  updateScope,
  getScopeIdFromAssetMeta,
};

function updateScope(assetCreationMeta: IUIAssetCreationMeta, updatedScope: IScopeModel): IUIAssetCreationMeta {
  assetCreationMeta.scope = updatedScope.scope;
  if (updatedScope.scope === Scope.Project) {
    assetCreationMeta.clusterId = null;
    assetCreationMeta.departmentId = null;
    assetCreationMeta.projectId = updatedScope.projectId;
  } else if (updatedScope.scope === Scope.Department) {
    assetCreationMeta.clusterId = null;
    assetCreationMeta.projectId = null;
    assetCreationMeta.departmentId = updatedScope.departmentId;
  } else if (updatedScope.scope === Scope.Cluster) {
    assetCreationMeta.projectId = null;
    assetCreationMeta.departmentId = null;
    assetCreationMeta.clusterId = updatedScope.clusterId;
  } else {
    assetCreationMeta.clusterId = null;
    assetCreationMeta.projectId = null;
    assetCreationMeta.departmentId = null;
  }
  return assetCreationMeta;
}

function getScopeIdFromAssetMeta(meta: IUIAssetCreationMeta): string {
  const { clusterId, departmentId, projectId, scope } = meta;
  switch (scope) {
    case Scope.Cluster:
      return clusterId || "";
    case Scope.Department:
      return departmentId || "";
    case Scope.Project:
      return projectId?.toString() || "";
    default:
      return "";
  }
}
