<template>
  <section class="access-input-list">
    <div
      v-for="item in itemsData"
      class="access-input-container q-mb-md q-pa-md row justify-between items-center"
      :key="item.id"
    >
      <q-input
        v-model="item.text"
        @update:model-value="onItemChanged"
        no-error-icon
        :label="item.label"
        stack-label
        :rules="validationRules"
        ref="inputRefs"
        class="col-8"
      />
      <q-btn
        v-if="item.removable"
        size="md"
        icon="fa-regular fa-xmark"
        flat
        round
        aid="remove-item-button"
        @click="removeItem(item.id)"
      />
    </div>

    <q-btn
      aid="add-item-button"
      class="add-item-button"
      flat
      color="primary"
      :label="`+ ${addItemBtnLabel}`"
      @click="addNewItem"
    ></q-btn>
  </section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { ValidationRule } from "quasar";

import { makeId } from "@/utils/common.util";

import type { IAccessInput } from "./access-input-list.model";

function getEmptyItem(label: string): IAccessInput {
  return {
    id: makeId(),
    text: "",
    label,
    removable: true,
  };
}

export default defineComponent({
  components: {},
  props: {
    items: {
      type: Array as PropType<Array<IAccessInput>>,
      default: () => [],
    },
    inputLabel: {
      type: String as PropType<string>,
      required: true,
    },
    addItemBtnLabel: {
      type: String as PropType<string>,
      required: true,
    },
    validationRules: {
      type: Array as PropType<ValidationRule[]>,
      default: () => [],
    },
  },
  data() {
    return {
      itemsData: [] as IAccessInput[],
    };
  },
  mounted() {
    this.initList();
  },
  methods: {
    initList(): void {
      this.itemsData = this.items.map((item: IAccessInput) => ({
        ...item,
        id: item.id || makeId(),
      }));

      if (this.itemsData.length === 0) {
        this.itemsData.push(getEmptyItem(this.inputLabel));
        this.focusInput();
      }

      if (this.itemsData.length === 1) {
        this.itemsData[0].removable = false;
      }
    },
    focusInput(): void {
      this.$nextTick(() => {
        const lastIndex = this.itemsData.length - 1;
        const inputsRefs = this.$refs.inputRefs as HTMLInputElement[];
        inputsRefs[lastIndex]?.focus();
      });
    },
    async addNewItem(): Promise<void> {
      this.itemsData.push(getEmptyItem(this.inputLabel));
      if (this.itemsData.length > 1) {
        this.itemsData = this.itemsData.map((item: IAccessInput) => ({
          ...item,
          removable: true,
        }));
      }
      await this.focusInput();
      this.onItemChanged();
    },
    removeItem(itemId?: string): void {
      this.itemsData = this.itemsData.filter((item: IAccessInput) => item.id !== itemId);
      if (this.itemsData.length === 1) {
        this.itemsData[0].removable = false;
      }

      this.onItemChanged();
    },
    onItemChanged(): void {
      this.$emit(
        "changed",
        this.itemsData.map((item: IAccessInput) => ({
          text: item.text,
          label: item.label,
        })),
      );
    },
  },
  watch: {
    inputLabel(): void {
      this.initList();
    },
  },
});
</script>
<style lang="scss" scoped>
.access-input-list {
  width: 100%;

  .access-input-container {
    background-color: $body-background-color;
  }
}
</style>
